import React from 'react';
import Newsletter from '../components/preInscricao';
import { useNavigate } from 'react-router-dom';

import { FaPhone } from "react-icons/fa6";
import { MdEmail, MdHome } from "react-icons/md";

const Contacto = () => {
    const navigate = useNavigate();

    return (
        <>
            <main className='px-8 lg:px-24'>
                <h1 className="pt-6 text-center title">
                    Entra em contacto
                </h1>

                <div className='grid grid-cols-1 gap-3 mt-12 sm:grid-cols-2'>
                    <div className='flex items-center gap-4 w-fit'>
                        <div className='p-3 rounded-full bg-brandGreen'>
                            <FaPhone className="w-5 h-6 text-blue-600" />
                        </div>
                        <p className='text-black dark:text-white'>915 134 027</p>
                    </div>
                    <div className='flex items-center gap-4 w-fit'>
                        <div className='p-3 rounded-full bg-brandGreen'>
                            <FaPhone className="w-5 h-6 text-blue-600" />
                        </div>
                        <p className='text-black dark:text-white'>213 013 869</p>
                    </div>
                    <div className='flex items-center gap-4 w-fit'>
                        <div className='p-3 rounded-full bg-brandGreen'>
                            <MdEmail className="w-6 h-6 text-blue-600" />
                        </div>
                        <p className='text-black dark:text-white'>info.infanterestelo@gmail.com</p>
                    </div>
                    <div className='flex items-center gap-4 w-fit'>
                        <div className='p-3 rounded-full bg-brandGreen'>
                            <MdEmail className="w-6 h-6 text-blue-600" />
                        </div>
                        <p className='text-black dark:text-white'>escolaconducao.i.restelo@gmail.com</p>
                    </div>
                    <div className='flex items-center gap-4 w-fit'>
                        <div className='p-3 rounded-full bg-brandGreen'>
                            <MdHome className="w-6 h-6 text-blue-600" />
                        </div>
                        <p className='text-black dark:text-white'> Rua Tristão Vaz, nº 7 – 1º A 1400-350 Lisboa</p>
                    </div>
                </div>



                <div className="flex flex-col mt-12 items-center-center sm:mt-6">
                    <h3 className="text-center subtitle sm:text-left">Onde estamos</h3>
                    <iframe className="w-[95%] sm:w-full mx-auto mt-2 sm:h-full sm:mx-0" style={{ height: "400px" }}
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3113.5150929029737!2d-9.20795792460409!3d38.70598145797671!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1ecb592005e579%3A0xe5e10a17379ed941!2sEscola%20de%20Condu%C3%A7%C3%A3o%20Infante%20do%20Restelo!5e0!3m2!1spt-PT!2spt!4v1706713142474!5m2!1spt-PT!2spt"
                        allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>

                <Newsletter />
            </main >
        </>
    );
};
export default Contacto;