import React from 'react';
import Newsletter from '../components/preInscricao';

const Aluno = () => {

    return (
        <>
            <main className='px-8 lg:px-24'>
                <h1 className="pt-6 text-center title">
                    Parceiros
                </h1>
                <div className='grid items-center justify-center grid-cols-1 gap-10 mt-6 sm:grid-cols-3'>
                    <img className='w-64 mx-auto h-52' src={process.env.PUBLIC_URL + "/images/parceiros/encostaSol.jpg"}alt="" />
                    <img className='w-64 mx-auto h-52' src={process.env.PUBLIC_URL + "/images/parceiros/estoril.jpg"}alt="" />
                    <img className='w-64 mx-auto h-52' src={process.env.PUBLIC_URL + "/images/parceiros/andancas.jpg"}alt="" />
                </div>
                <Newsletter />
            </main >
        </>
    );
};
export default Aluno;