import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {

    return (
        <footer className="shadow bg-slate-300 dark:bg-slate-900">
            <div className="w-full px-10 py-4">
                <div className="sm:flex sm:items-center sm:justify-between">
                    <Link to={"/"} className="flex items-center mb-4 space-x-3 sm:mb-0 rtl:space-x-reverse">
                        <img src={process.env.PUBLIC_URL + '/images/logo.png'} className="h-16" alt="Logo" />
                    </Link>
                    <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024
                        José T. Lopes | All Rights Reserved.</span>
                </div>
            </div>
        </footer>
    );
};

export default Footer;