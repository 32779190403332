import React from 'react';
import Newsletter from '../../components/preInscricao';

const Ligeiros = () => {

    return (
        <>
            <main>
                <h1 className="pt-6 text-center title">
                    Ligeiros
                </h1>
                <div className="flex flex-col max-w-4xl gap-5 mx-auto mt-12">
                    <div className="bg-gray-100 collapse collapse-arrow dark:bg-gray-900">
                        <input type="checkbox" />
                        <div className="text-xl font-medium text-black dark:text-gray-100 collapse-title">
                            Categoria B
                        </div>
                        <div className="px-6 sm:px-10 collapse-content">
                            <h1 className="subtitle">Automóveis Ligeiros</h1>
                            <h2 className="mt-6 text-lg text-black dark:text-white">Habilita a conduzir:</h2>
                            <ul className="flex flex-col gap-1">
                                <li className="accordionItem">Ligeiros</li>
                                <li className="accordionItem">Motociclos até 125cc de cilindrada</li>
                                <li className="accordionItem">Quadriciclos</li>
                                <li className="accordionItem">Ciclomotores e motociclos ambos de 3 rodas</li>
                                <li className="accordionItem">Tractores agrícolas desde que o peso máximo não exceda 6000kg</li>
                                <li className="accordionItem">Máquinas agrícolas, florestais e industriais ligeiras</li>
                                <li className="accordionItem">Motocultivador</li>
                            </ul>
                            <h2 className="mt-6 text-lg text-black dark:text-white">Requisitos:</h2>
                            <ul className="flex flex-col gap-1">
                                <li className="accordionItem">Idade mínima 18 anos (Pode inscrever-se aos 17 anos e 6 meses)</li>
                                <li className="accordionItem">Aptidão física e mental</li>
                                <li className="accordionItem">Residir em Portugal</li>
                                <li className="accordionItem">Saber ler e escrever</li>
                            </ul>
                            <h2 className="mt-6 text-lg text-black dark:text-white">Documentos:</h2>
                            <ul className="flex flex-col gap-1">
                                <li className="accordionItem">2 Fotografias (tipo passe)</li>
                                <li className="accordionItem">Fotocópia do documento de identificação pessoal (B.I. ou Cartão do Cidadão)</li>
                                <li className="accordionItem">Fotocópia do Cartão de Contribuinte (Se apresentar o Cartão de Cidadão não é necessário)</li>
                                <li className="accordionItem">Atestado Médico (Art. 26º nº 1 do RHLC)</li>
                            </ul>
                        </div>
                    </div>
                    <div className="bg-gray-100 collapse collapse-arrow dark:bg-gray-900">
                        <input type="checkbox" />
                        <div className="text-xl font-medium text-black dark:text-gray-100 collapse-title">
                            Categoria B1
                        </div>
                        <div className="px-6 sm:px-10 collapse-content">
                            <h1 className="subtitle">Triciclos e quadriciclos</h1>
                            <h2 className="mt-6 text-lg text-black dark:text-white">Habilita a conduzir:</h2>
                            <ul className="flex flex-col gap-1">
                                <li className="accordionItem">Triciclos</li>
                                <li className="accordionItem">Quadriciclos</li>
                            </ul>
                            <h2 className="mt-6 text-lg text-black dark:text-white">Requisitos:</h2>
                            <ul className="flex flex-col gap-1">
                                <li className="accordionItem">Idade mínima 16 anos (Pode inscrever-se aos 15 anos e 6 meses)</li>
                                <li className="accordionItem">Aptidão física e mental</li>
                                <li className="accordionItem">Residir em Portugal</li>
                                <li className="accordionItem">Saber ler e escrever</li>
                            </ul>
                            <h2 className="mt-6 text-lg text-black dark:text-white">Documentos:</h2>
                            <ul className="flex flex-col gap-1">
                                <li className="accordionItem">2 Fotografias (tipo passe)</li>
                                <li className="accordionItem">Fotocópia do documento de identificação pessoal (B.I. ou Cartão do Cidadão)</li>
                                <li className="accordionItem">Fotocópia do Cartão de Contribuinte (Se apresentar o Cartão de Cidadão não é necessário)</li>
                                <li className="accordionItem">Atestado Médico (Art. 26º nº 1 do RHLC)</li>
                            </ul>
                            <h3 className='accordionSubtitle'>Para menores de 18 anos é necessário:</h3>
                            <ul>
                                <li className="accordionItem">Certidão de Nascimento Narrativa Completa</li>
                                <li className="accordionItem">Autorização da pessoa que exerce o poder paternal e fotocópia do BI da mesma</li>
                            </ul>
                        </div>
                    </div>
                    <div className="bg-gray-100 collapse collapse-arrow dark:bg-gray-900">
                        <input type="checkbox" />
                        <div className="text-xl font-medium text-black dark:text-gray-100 collapse-title">
                            Categoria B+E
                        </div>
                        <div className="px-6 sm:px-10 collapse-content">
                            <h2 className="mt-6 text-lg text-black dark:text-white">Em atualização...</h2>
                        </div>
                    </div>
                </div>
                <div className="py-6 dark:bg-gray-800">
                    <div className="container flex flex-col justify-center p-4 mx-auto">
                        <h2 className='mb-4 subtitle'>A nossa frota</h2>
                        <div className="galleryContainer">
                            <img className="galleryImage" src={process.env.PUBLIC_URL + "/images/ligeiros/car1.jpg"} />
                            <img className="galleryImage" src={process.env.PUBLIC_URL + "/images/ligeiros/car2.jpg"} />
                            <img className="galleryImage" src={process.env.PUBLIC_URL + "/images/ligeiros/car3.png"} />
                            {/* <img className="galleryImage" src={process.env.PUBLIC_URL + "/images/ligeiros/ligeiro4.png"} /> */}
                        </div>
                    </div>
                </div>
                <Newsletter />
            </main >
        </>
    );
};
export default Ligeiros;