import React from 'react';
import Newsletter from '../components/preInscricao';

const FAQ = () => {

    return (
        <>
            <main className='px-8 lg:px-24'>
                <h1 className="pt-6 text-center title">
                    F.A.Q.
                </h1>
                <div className="flex flex-col max-w-4xl gap-5 mx-auto mt-12">
                    <div className="bg-gray-100 collapse collapse-arrow dark:bg-gray-900">
                        <input type="checkbox" />
                        <div className="text-xl font-medium text-black dark:text-gray-100 collapse-title">
                            Qual é o número mínimo de aulas de código que tenho de fazer na categoria de Ligeiros de Passageiros?
                        </div>
                        <div className="px-6 sm:px-10 collapse-content">
                            <p className="text-gray-700 dark:text-gray-300">Os alunos têm obrigatoriamente que assistir a 28 aulas teóricas. No entanto, podem continuar a assistir às aulas depois de fazer as 28. Qualquer aula teórica para além das 28 aulas obrigatórias é gratuita.</p>
                        </div>
                    </div>
                    <div className="bg-gray-100 collapse collapse-arrow dark:bg-gray-900">
                        <input type="checkbox" />
                        <div className="text-xl font-medium text-black dark:text-gray-100 collapse-title">
                            Qual é o número mínimo de aulas de condução que tenho de fazer na categoria de Ligeiros de Passageiros?
                        </div>
                        <div className="px-6 sm:px-10 collapse-content">
                            <p className="text-gray-700 dark:text-gray-300">Os alunos têm que ter 32 aulas práticas. É o número mínimo obrigatório. No entanto, não há limite máximo de aulas, sendo este apenas dependente da aprendizagem, da vontade do aluno e do aconselhamento do instrutor.</p>
                        </div>
                    </div>
                    <div className="bg-gray-100 collapse collapse-arrow dark:bg-gray-900">
                        <input type="checkbox" />
                        <div className="text-xl font-medium text-black dark:text-gray-100 collapse-title">
                            No caso de reprovar no código, o que tenho de fazer?
                        </div>
                        <div className="px-6 sm:px-10 collapse-content">
                            <p className="text-gray-700 dark:text-gray-300">
                                Os alunos que reprovem no código têm que assistir a cinco aulas de código da temática que erraram. As cinco aulas são obrigatórias, no entanto podem assistir às aulas que quiserem depois de terem as cinco obrigatórias. Só se pode requerer novo exame depois das cinco lições obrigatórias.
                            </p>
                        </div>
                    </div>
                    <div className="bg-gray-100 collapse collapse-arrow dark:bg-gray-900">
                        <input type="checkbox" />
                        <div className="text-xl font-medium text-black dark:text-gray-100 collapse-title">
                            No caso de reprovar na condução, o que tenho de fazer?
                        </div>
                        <div className="px-6 sm:px-10 collapse-content">
                            <p className="text-gray-700 dark:text-gray-300">
                                No caso de reprovar no exame de condução o aluno terá de ter mais cinco aulas focando os aspectos que falhou no exame.
                            </p>
                        </div>
                    </div>
                    <div className="bg-gray-100 collapse collapse-arrow dark:bg-gray-900">
                        <input type="checkbox" />
                        <div className="text-xl font-medium text-black dark:text-gray-100 collapse-title">
                            Qual é o veículo que vou conduzir no dia do exame?
                        </div>
                        <div className="px-6 sm:px-10 collapse-content">
                            <p className="text-gray-700 dark:text-gray-300">
                                O veículo será o mesmo que utilizou na aprendizagem para que não haja qualquer diferença no ambiente de condução.
                            </p>
                        </div>
                    </div>
                    <div className="bg-gray-100 collapse collapse-arrow dark:bg-gray-900">
                        <input type="checkbox" />
                        <div className="text-xl font-medium text-black dark:text-gray-100 collapse-title">
                            Posso ter aulas de condução antes de ter passado no código?
                        </div>
                        <div className="px-6 sm:px-10 collapse-content">
                            <p className="text-gray-700 dark:text-gray-300">
                                As aulas de condução e as aulas de código devem ser feitas em simultâneo. Quando o aluno tiver oito aulas de código já pode começar a ter aulas práticas. No entanto só é possível realizar o exame de condução após a aprovação no exame teórico.
                            </p>
                        </div>
                    </div>
                    <div className="bg-gray-100 collapse collapse-arrow dark:bg-gray-900">
                        <input type="checkbox" />
                        <div className="text-xl font-medium text-black dark:text-gray-100 collapse-title">
                            Quantas aulas posso ter por dia?
                        </div>
                        <div className="px-6 sm:px-10 collapse-content">
                            <p className="text-gray-700 dark:text-gray-300">
                                Não há um limite do número de aulas de código por dia. Quanto às aulas de condução é aconselhado um máximo de 2 lições.
                            </p>
                        </div>
                    </div>
                    <div className="bg-gray-100 collapse collapse-arrow dark:bg-gray-900">
                        <input type="checkbox" />
                        <div className="text-xl font-medium text-black dark:text-gray-100 collapse-title">
                            A partir de que idade posso tirar a carta de ligeiros?
                        </div>
                        <div className="px-6 sm:px-10 collapse-content">
                            <p className="text-gray-700 dark:text-gray-300">
                                Aos 17 anos e 6 meses já se pode inscrever na Escola de Condução e iniciar o processo de aprendizagem tanto das aulas de código como de condução. No entanto, só com 18 anos é que o aluno pode ser proposto a exame.
                            </p>
                        </div>
                    </div>
                    <div className="bg-gray-100 collapse collapse-arrow dark:bg-gray-900">
                        <input type="checkbox" />
                        <div className="text-xl font-medium text-black dark:text-gray-100 collapse-title">
                            Quais os documentos necessários para realizar os exames teórico e prático?
                        </div>
                        <div className="px-6 sm:px-10 collapse-content">
                            <p className="text-gray-700 dark:text-gray-300">
                                No dia do exame de código e do exame de condução o aluno tem de ter consigo o Bilhete de Identidade válido e a Licença de Aprendizagem.
                            </p>
                        </div>
                    </div>
                    <div className="bg-gray-100 collapse collapse-arrow dark:bg-gray-900">
                        <input type="checkbox" />
                        <div className="text-xl font-medium text-black dark:text-gray-100 collapse-title">
                            Posso fazer o Exame de Código num Centro de Exames e o de Condução noutro?
                        </div>
                        <div className="px-6 sm:px-10 collapse-content">
                            <p className="text-gray-700 dark:text-gray-300">
                                Não. O aluno realizando o exame teórico num centro, tem de realizar o exame prático nesse mesmo centro.
                            </p>
                        </div>
                    </div>
                </div>
                <Newsletter />
            </main >
        </>
    );
};
export default FAQ;