import React from 'react';
import Newsletter from '../components/preInscricao';
import { useNavigate } from 'react-router-dom';

const Ensino = () => {
    const navigate = useNavigate();

    return (
        <>
            <main className='px-8 lg:px-24'>
                <h1 className="pt-6 text-center title">
                    Ensino
                </h1>
                {/*CARDS */}
                <div className="relative z-10 grid grid-cols-1 mt-12 gap-y-5 gap-x-16 sm:grid-cols-3">
                    <div onClick={() => navigate("/ensino/motociclos")} className={`cardContainer bg-cover group bg-center`} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/motociclos/moto1.jpg)` }}>
                        <div className="cardFilter"></div>
                        <div className="relative flex items-center justify-center w-full h-full px-4 sm:px-6 lg:px-4">
                            <h3 className="cardTitle">
                                Motociclos
                            </h3>
                        </div>
                    </div>
                    <div onClick={() => navigate("/ensino/ligeiros")} className={`cardContainer group`} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/ligeiros/car1.jpg)` }}>
                        <div className="cardFilter"></div>
                        <div className="relative flex items-center justify-center w-full h-full px-4 sm:px-6 lg:px-4">
                            <h3 className="cardTitle">
                                Ligeiros
                            </h3>
                        </div>
                    </div>
                    <div onClick={() => navigate("/ensino/pesados")} className={`cardContainer bg-center group`} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/cards/bus.jpg)` }}>
                        <div className="cardFilter"></div>
                        <div className="relative flex items-center justify-center w-full h-full px-4 sm:px-6 lg:px-4">
                            <h3 className="cardTitle">
                                Pesados
                            </h3>
                        </div>
                    </div>
                </div>
                {/* CARDS END */}
                <Newsletter />
            </main >
        </>
    );
};
export default Ensino;