import React from 'react';
import Newsletter from '../../components/preInscricao';

const Motociclos = () => {

    return (
        <>
            <main>
                <h1 className="pt-6 text-center title">
                    Motociclos
                </h1>
                <div className="flex flex-col max-w-4xl gap-5 mx-auto mt-12">
                    <div className="bg-gray-100 collapse collapse-arrow dark:bg-gray-900">
                        <input type="checkbox" />
                        <div className="text-xl font-medium text-black dark:text-gray-100 collapse-title">
                            Categoria A1
                        </div>
                        <div className="px-6 sm:px-10 collapse-content">
                            <h1 className="subtitle">Motociclos de cilindrada não superior a 125cm3 ou de potência máxima até 11Kw=14,96cv.</h1>
                            <h2 className="mt-6 text-lg text-black dark:text-white">Habilita a conduzir:</h2>
                            <ul className="flex flex-col gap-1">
                                <li className='accordionItem'>Ciclomotores</li>
                                <li className='accordionItem'>Motociclos de cilindrada não superior a 50cm<sup>3</sup></li>
                                <li className='accordionItem'>Triciclos</li>
                            </ul>
                            <h2 className="mt-6 text-lg text-black dark:text-white">Requisitos:</h2>
                            <ul className="flex flex-col gap-1">
                                <li className='accordionItem'>Idade mínima 16 anos (Pode inscrever-se aos 15 anos e 6 meses)</li>
                                <li className='accordionItem'>Aptidão física e mental</li>
                                <li className='accordionItem'>Residir em Portugal</li>
                                <li className='accordionItem'>Saber ler e escrever</li>
                            </ul>
                            <h2 className="mt-6 text-lg text-black dark:text-white">Documentos:</h2>
                            <ul className="flex flex-col gap-1">
                                <li className='accordionItem'>2 Fotografias (tipo passe)</li>
                                <li className='accordionItem'>Fotocópia do documento de identificação pessoal (B.I. ou Cartão do Cidadão)</li>
                                <li className='accordionItem'>Fotocópia do Cartão de Contribuinte (Se apresentar o Cartão de Cidadão não é necessário)</li>
                                <li className='accordionItem'>Atestado Médico (Art. 26º nº 1 do RHLC)</li>
                            </ul>
                            <h3 className='accordionSubtitle'>Para menores de 18 anos é necessário:</h3>
                            <ul>
                                <li className='accordionItem'>Certidão de Nascimento Narrativa Completa</li>
                                <li className='accordionItem'>Autorização da pessoa que exerce o poder paternal e fotocópia do BI da mesma</li>
                            </ul>
                        </div>
                    </div>
                    <div className="bg-gray-100 collapse collapse-arrow dark:bg-gray-900">
                        <input type="checkbox" />
                        <div className="text-xl font-medium text-black dark:text-gray-100 collapse-title">
                            Categoria A2
                        </div>
                        <div className="px-6 sm:px-10 collapse-content">
                            <h1 className="subtitle">Motociclos de cilindrada não superior a 250cc ou de potência máxima até 25Kw=35cv.</h1>
                            <h2 className="mt-6 text-lg text-black dark:text-white">Habilita a conduzir:</h2>
                            <ul className="flex flex-col gap-1">
                                <li className='accordionItem'>Ciclomotores</li>
                                <li className='accordionItem'>Motociclos</li>
                                <li className='accordionItem'>Triciclos</li>
                            </ul>
                            <h2 className="mt-6 text-lg text-black dark:text-white">Requisitos:</h2>
                            <ul className="flex flex-col gap-1">
                                <li className='accordionItem'>Idade mínima 18 anos (Pode inscrever-se aos 17 anos e 6 meses)</li>
                                <li className='accordionItem'>Aptidão física e mental</li>
                                <li className='accordionItem'>Residir em Portugal</li>
                                <li className='accordionItem'>Saber ler e escrever</li>
                            </ul>
                            <h2 className="mt-6 text-lg text-black dark:text-white">Documentos:</h2>
                            <ul className="flex flex-col gap-1">
                                <li className='accordionItem'>2 Fotografias (tipo passe)</li>
                                <li className='accordionItem'>Fotocópia do documento de identificação pessoal (B.I. ou Cartão do Cidadão)</li>
                                <li className='accordionItem'>Fotocópia do Cartão de Contribuinte (Se apresentar o Cartão de Cidadão não é necessário)</li>
                                <li className='accordionItem'>Atestado Médico (Art. 26º nº 1 do RHLC)</li>
                            </ul>
                        </div>
                    </div>
                    <div className="bg-gray-100 collapse collapse-arrow dark:bg-gray-900">
                        <input type="checkbox" />
                        <div className="text-xl font-medium text-black dark:text-gray-100 collapse-title">
                            Categoria A
                        </div>
                        <div className="px-6 sm:px-10 collapse-content">
                            <h2 className="mt-6 text-lg text-black dark:text-white">Habilita a conduzir:</h2>
                            <ul className="flex flex-col gap-1">
                                <li className='accordionItem'>Qualquer motociclo</li>
                                <li className='accordionItem'>Ciclomotores</li>
                                <li className='accordionItem'>Triciclos</li>
                            </ul>
                            <h2 className="mt-6 text-lg text-black dark:text-white">Requisitos:</h2>
                            <ul className="flex flex-col gap-1">
                                <li className='accordionItem'>Idade mínima 24 anos ( Pode inscrever-se aos 23 anos e 6 meses)</li>
                                <li className='accordionItem'>Aptidão física e mental</li>
                                <li className='accordionItem'>Residir em Portugal</li>
                                <li className='accordionItem'>Saber ler e escrever</li>
                            </ul>
                            <h2 className="mt-6 text-lg text-black dark:text-white">Documentos:</h2>
                            <ul className="flex flex-col gap-1">
                                <li className='accordionItem'>2 Fotografias (tipo passe)</li>
                                <li className='accordionItem'>Fotocópia do documento de identificação pessoal (B.I. ou Cartão do Cidadão)</li>
                                <li className='accordionItem'>Fotocópia do Cartão de Contribuinte (Se apresentar o Cartão de Cidadão não é necessário)</li>
                                <li className='accordionItem'>Atestado Médico (Art. 26º nº 1 do RHLC)</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="py-6 dark:bg-gray-800">

                    <div className="container flex flex-col justify-center p-4 mx-auto">
                        <h2 className='mb-4 subtitle'>A nossa frota</h2>
                        <div className="galleryContainer">
                            <img className="galleryImage" src={process.env.PUBLIC_URL+"/images/motociclos/moto1.jpg"} />
                            <img className="galleryImage" src={process.env.PUBLIC_URL+"/images/motociclos/moto2.jpg"} />
                            <img className="galleryImage" src={process.env.PUBLIC_URL+"/images/motociclos/moto3.jpg"} />
                            {/* <img className="galleryImage" src={process.env.PUBLIC_URL+"/images/motociclos/moto4.png"} /> */}
                        </div>
                    </div>
                </div>
                <Newsletter />
            </main >
        </>
    );
};
export default Motociclos;